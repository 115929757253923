import React from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useAuthContext } from "../../hooks/useAuthContext";

export default function Sidebar() {
  const { user, authIsReady } = useAuthContext();
  return (
    <div
      className="col-md-3 col-lg-2 d-none d-md-block p-4 bg-light border-top affix-"
      style={{ height: "calc(100vh - 56px)", position: "fixed", top: "56px" }}
    >
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <NavLink to="/" className="nav-link ({ isActive }) => (isActive ? 'active' : '') ">
            <i className="bi bi-house-fill pe-2"></i>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to={`/profile/${user.uid}`} className="nav-link ({ isActive }) => (isActive ? 'active' : '') ">
            <i className="bi bi-person-fill pe-2"></i>
            Profile
          </NavLink>
        </li>
        <li>
          <NavLink to="/events" className="nav-link ({ isActive }) => (isActive ? 'active' : '') ">
            <i className="bi bi-calendar-date-fill pe-2"></i>
            Events
          </NavLink>
        </li>
        <li>
          <NavLink to="/roster" className="nav-link ({ isActive }) => (isActive ? 'active' : '') ">
            <i className="bi bi-person-circle pe-2"></i>
            Roster
          </NavLink>
        </li>
        <li>
          <NavLink to="/teams" className="nav-link ({ isActive }) => (isActive ? 'active' : '') ">
            <i className="bi bi-people-fill pe-2"></i>
            Teams
          </NavLink>
        </li>
        <li>
          <NavLink to="/equipment" className="nav-link ({ isActive }) => (isActive ? 'active' : '') ">
            <i className="bi bi-backpack2 pe-2"></i>
            Equipment <span className="badge rounded-pill bg-success text-light ms-2">Beta</span>
          </NavLink>
        </li>
        <li>
          <a href="#" className="nav-link link-dark">
            <i className="bi bi-shop pe-2"></i>
            Marketplace <span className="badge rounded-pill bg-secondary text-light ms-2">Soon™</span>
          </a>
        </li>
      </ul>
    </div>
  );
}
