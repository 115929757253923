import { useEffect, useState } from "react";
import { projectFirestore } from "../config/config";

export const useDocument = (collection, id) => {
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    setIsPending(true);
    const ref = projectFirestore.collection(collection).doc(id);
    const unsub = ref.onSnapshot(
      (snapshot) => {
        if (snapshot.data()) {
          setDocument({ ...snapshot.data(), id: snapshot.id });
          setError(null);
          setIsPending(false);
        } else {
          setError("document does not exist");
          setIsPending(false);
        }
      },
      (err) => {
        console.warn(err.message);
        setError("failed to get document");
        setIsPending(false);
      },
    );

    return () => unsub();
  }, [collection, id]);

  return { document, isPending, error };
};
