import { React, useState, useEffect } from "react";
import Avatar from "../avatar/Avatar";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useFirestore } from "../../hooks/useFirestore";
import { timestamp } from "../../config/config";
import deletedProfile from "../../assets/deletedProfile.webp";
import { useDatabase } from "../../hooks/useDatabase";

// NewsItem component
export default function NewsItem({ data, profiles }) {
  return (
    <div>
      {data &&
        data.map((item) => (
          <div key={item.id} className="m-3">
            <div className="card">
              <div className="card-body">
                <NewsItemWithUser item={item} profiles={profiles} />
              </div>
              <CardFooter item={item} profiles={profiles} />
            </div>
          </div>
        ))}
    </div>
  );
}

// Helper function to format timestamp
function timeAgo(timestamp) {
  const now = new Date();
  const timeDiff = now - new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000); // Difference in milliseconds
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) return "less than a minute ago";
  if (minutes < 60) return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
  if (hours < 24) return hours === 1 ? "an hour ago" : `${hours} hours ago`;
  if (days < 7) return days === 1 ? "a day ago" : `${days} days ago`;

  const weeks = Math.floor(days / 7);
  return weeks === 1 ? "a week ago" : `${weeks} weeks ago`;
}

// NewsItemWithUser component: Uses passed profiles to display user data
function NewsItemWithUser({ item, profiles }) {
  const createdTime = timeAgo(item.createdAt);

  // Fetch the user profile from the passed profiles object
  const userProfile = profiles[item.user];
  let profPic = userProfile?.deleted ? deletedProfile : userProfile?.profileData?.photoURL;

  if (!userProfile) return <div>Loading user data...</div>;

  return (
    <div className="d-flex">
      <Link to={`/profile/${item.user}`}>
        <Avatar src={profPic} className="align-self-center" />
      </Link>
      <span className="align-self-center ms-3">
        <div>
          <Link to={`/profile/${item.user}`} style={{ textDecoration: "none" }}>
            {userProfile.profileData.firstName} {userProfile.profileData.surName}
          </Link>{" "}
          <span className="text-muted">- {createdTime}</span>
        </div>
        <div>
          {getActionText(item)} <Link to={`/events/${item.entryId}`}>{item.categoryName}</Link>.
        </div>
      </span>
    </div>
  );
}

// Returns the action text based on the event type
function getActionText(item) {
  switch (item.category) {
    case "event":
      return `has ${item.type}d an ${item.category}:`;
    case "eventInterested":
      return "is interested in";
    case "eventGoing":
      return "is going to";
    default:
      return "";
  }
}

// CardFooter component: Renders likes, comments, and additional content
function CardFooter({ item, profiles }) {
  const { updateDocument } = useDatabase("newsFeed");
  const { user } = useAuthContext();

  const [comment, setComment] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (item.comments) {
      setComments(item.comments);
    }
  }, [item.comments]);

  const toggleLike = () => {
    if (item.likes?.includes(user.uid)) {
      item.likes = item.likes.filter((id) => id !== user.uid);
    } else {
      item.likes = item.likes ? [...item.likes, user.uid] : [user.uid];
    }
    updateDocument(item.id, item);
  };

  const toggleComments = () => {
    const cardFooter = document.getElementById(`${item.id}-commentsContainer`);
    setShowComments(!showComments);
    if (cardFooter) {
      cardFooter.classList.toggle("d-none");
    }
  };

  const handleSubmitComment = (e) => {
    e.preventDefault();
    const createdAt = timestamp.fromDate(new Date());
    if (comment) {
      item.comments = item.comments
        ? [...item.comments, { comment, user: user.uid, createdAt, id: Math.random() }]
        : [{ comment, user: user.uid, createdAt, id: Math.random() }];
      updateDocument(item.id, item);
      setComment("");
      setComments(item.comments);
    }
  };

  return (
    <div>
      <div className="card-footer">
        <span className="me-3" onClick={toggleLike} role="button">
          {item.likes?.includes(user?.uid) ? (
            <i className="bi bi-hand-thumbs-up-fill text-primary me-1"></i>
          ) : (
            <i className="bi bi-hand-thumbs-up me-1"></i>
          )}
          {item.likes?.length > 0 ? item.likes.length : 0}
        </span>{" "}
        <span onClick={toggleComments} role="button">
          {!showComments && <i className="bi bi-chat me-1"></i>}
          {showComments && <i className="bi bi-chat-fill me-1 text-primary"></i>}
          {item.comments?.length > 0 ? item.comments.length : 0}
        </span>
      </div>
      <div className={`card-footer d-none`} id={`${item.id}-commentsContainer`}>
        {comments?.map((comment) => {
          return (
            <div key={`${comment.createdAt.nanoseconds}-${comment.createdAt.seconds}`} id={comment.createdAt} className="d-flex">
              <CommentItemWithUser comment={comment} profiles={profiles} item={item} setComments={setComments} />
            </div>
          );
        })}
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Write a comment..."
          />
          <button className="btn btn-secondary" onClick={handleSubmitComment}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

// CommentItemWithUser component: Uses passed profiles to display user data for comments
function CommentItemWithUser({ comment, profiles, item, setComments }) {
  const { user } = useAuthContext();
  // console.log(profiles, comment, item);
  const userProfile = profiles[comment.user];
  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_CHAR = 300;
  const isLongComment = comment.comment.length > MAX_CHAR;

  const { updateDocument } = useDatabase("newsFeed");

  const toggleCommentTruncation = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDeleteComment = (commentId) => {
    if (window.confirm("Are you sure you wish to delete this comment?")) {
      item.comments = item.comments.filter((c) => c.id !== commentId);
      updateDocument(item.id, item);
      setComments(item.comments);
    }
  };

  if (!userProfile) return <div>Loading user data...</div>;

  const createdTime = timeAgo(comment.createdAt);
  return (
    <div className="d-flex mb-1">
      <Link to={`/profile/${item.user}`}>
        <span className="comment-avatar">
          <Avatar src={userProfile.profileData?.photoURL} className="align-self-center" />
        </span>
      </Link>
      <span className="align-self-center ms-3 card p-2">
        <div>
          <Link to={`/profile/${item.user}`} style={{ textDecoration: "none" }}>
            {userProfile.profileData.firstName} {userProfile.profileData.surName}
          </Link>{" "}
          <span className="text-muted">- {createdTime}</span>
          {user.uid === comment.user && (
            <i className="bi bi-trash text-danger ms-2" onClick={() => handleDeleteComment(comment.id)} aria-hidden="true"></i>
          )}
        </div>
        <div>
          {isExpanded ? comment.comment : !isLongComment ? comment.comment : `${comment.comment.slice(0, MAX_CHAR)}...`}
          {isLongComment && (
            <button className="btn btn-link p-0" onClick={toggleCommentTruncation}>
              {isExpanded ? "Read less" : "Read more"}
            </button>
          )}
        </div>
      </span>
    </div>
  );
}
