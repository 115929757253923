import React, { useEffect, useState } from "react";

import Select from "react-select";

import Spacer from "../../components/spacer/spacer";

import { Modal, Form, InputGroup, Button, Row, Col } from "react-bootstrap";
import FloatingLabel from "react-bootstrap";

import { useDocument } from "../../hooks/useDocument";
import { useFirestore } from "../../hooks/useFirestore";
import { toast } from "react-toastify";

// TODO: add favourite item option
// TODO: prebuild some common equipment items/brands/models

export default function Equipment({ setLoading, user }) {
  const { document: equipment, isPending } = useDocument("equipment", user.uid);
  const { softDeleteDocument, addDocument, setDocument, updateDocument, response } = useFirestore("equipment");

  const [showSetupModal, setShowSetupModal] = useState(false);
  const [showCreateEquipmentModal, setShowCreateEquipmentModal] = useState(false);
  const [showEditEquipmentModal, setShowEditEquipmentModal] = useState(false);

  const [selectedType, setSelectedType] = useState("");
  const [formData, setFormData] = useState({});
  const [selectedItem, setSelectedItem] = useState("");

  const typeOptions = [
    { value: "Marker", label: "Marker" },
    { value: "Bottle", label: "Bottle" },
    { value: "Mask", label: "Mask" },
    { value: "Hopper", label: "Hopper" },
    { value: "Pack", label: "Pack" },
    { value: "Clothing", label: "Clothing" },
    { value: "Miscellaneous", label: "Miscellaneous" },
  ];

  const fieldOptions = {
    Marker: [
      { name: "brand", label: "Brand", type: "text" },
      { name: "model", label: "Model", type: "text" },
      { name: "elecMech", label: "Electric / Mechanical", type: "select", options: ["Electric", "Mechanical"] },
      { name: "colour", label: "Colour", type: "text" },
      { name: "serialNumber", label: "Serial Number", type: "text" },
      { name: "purchaseDate", label: "Purchase Date", type: "date" },
      { name: "serviceDate", label: "Service Date", type: "date" },
      { name: "notes", label: "Notes", type: "textarea" },
    ],
    Mask: [
      { name: "brand", label: "Brand", type: "text" },
      { name: "model", label: "Model", type: "text" },
      { name: "colour", label: "Colour", type: "text" },
      // { name: "purchaseDate", label: "Purchase Date", type: "date" },
      { name: "notes", label: "Notes", type: "textarea" },
    ],
    Bottle: [
      { name: "brand", label: "Brand", type: "text" },
      { name: "size", label: "Size", type: "select", options: ["13ci", "26ci", "48ci", "68ci", "80ci", "91ci"] },
      { name: "psi", label: "PSI", type: "select", options: ["3000", "4500"] },
      { name: "type", label: "Type", type: "select", options: ["Aluminium", "Carbon Fibre"] },
      { name: "testDate", label: "Next Test Date", type: "date" },
      { name: "deathDate", label: "Death Date", type: "date" },
      { name: "purchaseDate", label: "Purchase Date", type: "date" },
      {
        name: "notify",
        label: "Notify",
        type: "checkbox",
        note: "Get notified when the bottle is about to expire. Email verification required.",
      },
      { name: "notes", label: "Notes", type: "textarea" },
    ],
    Hopper: [
      { name: "brand", label: "Brand", type: "text" },
      { name: "model", label: "Model", type: "text" },
      { name: "elecGravity", label: "Electric/Gravity", type: "select", options: ["Electric", "Gravity"] },
      { name: "colour", label: "Colour", type: "text" },
      // { name: "purchaseDate", label: "Purchase Date", type: "date" },
      { name: "notes", label: "Notes", type: "textarea" },
    ],
    Pack: [
      { name: "brand", label: "Brand", type: "text" },
      { name: "model", label: "Model", type: "text" },
      { name: "capacity", label: "Capacity", type: "text" },
      { name: "colour", label: "Colour", type: "text" },
      // { name: "purchaseDate", label: "Purchase Date", type: "date" },
      { name: "notes", label: "Notes", type: "textarea" },
    ],
    Clothing: [
      { name: "type", label: "Type", type: "select", options: ["Jersey", "Pants", "Other"] },
      { name: "brand", label: "Brand", type: "text" },
      { name: "colour", label: "Colour", type: "text" },
      { name: "size", label: "Size", type: "text" },
      // { name: "purchaseDate", label: "Purchase Date", type: "date" },
      { name: "notes", label: "Notes", type: "textarea" },
    ],
    Miscellaneous: [
      { name: "name", label: "Name", type: "text" },
      // { name: "purchaseDate", label: "Purchase Date", type: "date" },
      { name: "notes", label: "Notes", type: "textarea" },
    ],
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.value);
    setFormData({});
  };

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  useEffect(() => {
    if (!isPending) {
      if (!equipment) {
        const setupEquipment = async () => {
          try {
            if (!equipment) {
              console.log("setting up equipment db");
              setShowSetupModal(true);
              await setDocument(user.uid, {}).then(() => {
                setTimeout(() => {
                  setShowSetupModal(false);
                }, 2000);
              });
            }
            setLoading(false);
          } catch (error) {
            console.error(error);
          }
        };
        setupEquipment();
      }
    }
    setLoading(false);
  }, [equipment, isPending]);

  const handleAddItem = (category) => {
    setSelectedType(category);
    setFormData({});
    setShowCreateEquipmentModal(true);
  };

  const handleSubmitNewEquipment = async (e) => {
    e.preventDefault();
    setSelectedType("");
    setShowCreateEquipmentModal(false);
    let time = Date.now().toString();
    try {
      await updateDocument(user.uid, {
        data: {
          ...equipment.data,
          [selectedType]: {
            ...(equipment.data && equipment.data[selectedType] ? equipment.data[selectedType] : {}),
            [time]: { ...formData },
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditItem = (item, category) => {
    setSelectedItem(item);
    setShowEditEquipmentModal(true);
    setSelectedType(category);
    setFormData({ ...equipment.data[category][item] });
  };

  const handleSubmitEditEquipment = async (e) => {
    e.preventDefault();
    setSelectedType("");
    setShowEditEquipmentModal(false);
    try {
      await updateDocument(user.uid, {
        data: {
          ...equipment.data,
          [selectedType]: {
            ...(equipment.data && equipment.data[selectedType] ? equipment.data[selectedType] : {}),
            [selectedItem]: { ...formData },
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteItem = (e) => {
    e.preventDefault();
    const confirmDelete = window.confirm("Are you sure you want to delete this item?");
    if (confirmDelete) {
      setSelectedType("");
      setShowEditEquipmentModal(false);
      try {
        updateDocument(user.uid, {
          data: {
            ...equipment.data,
            [selectedType]: Object.keys(equipment.data[selectedType]).reduce(
              (obj, key) => (key !== selectedItem ? { ...obj, [key]: equipment.data[selectedType][key] } : obj),
              {},
            ),
          },
        });
      } catch (error) {
        console.error(error);
      }
      toast.success("Item deleted!");
    }
  };

  return (
    <>
      <Spacer height="24px" />
      <div className="card">
        <div className="px-5 pt-5">
          <h1>Equipment</h1>
          <p>Your personal equipment database.</p>
        </div>
        <div className="px-5">
          {isPending && <p>Loading...</p>}
          {!equipment?.data && (
            <div>
              <p>Your equipment list is currently empty.</p>
              <div className="d-flex justify-content-center">
                <Button className="btn btn-primary mb-3" onClick={() => handleAddItem()}>
                  Start your collection
                </Button>
              </div>
            </div>
          )}
          {equipment?.data &&
            typeOptions
              // .filter(({ value: category }) => Object.keys(equipment.data[category] || {}).length > 0)
              .map(({ value: category }) => (
                <div key={category} className="mt-4 table-responsive">
                  <h3>
                    {category}
                    <Button className="btn btn-sm btn-primary ms-2" onClick={() => handleAddItem(category)}>
                      Add
                    </Button>
                  </h3>
                  <table className="table table-striped ">
                    <thead>
                      <tr>
                        {Object.values(equipment.data[category] || {}).length > 0 && <th>Edit</th>}
                        {fieldOptions[category]
                          ?.filter((field) =>
                            Object.values(equipment.data[category] || {}).some((item) => item[field.name] !== undefined),
                          )
                          .map(({ name, label }) => (
                            <th key={name}>{label}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(equipment.data[category] || {}).map(([id, item]) => (
                        <tr key={id}>
                          <td>
                            <button className="btn btn-sm btn-outline-primary" onClick={() => handleEditItem(id, category)}>
                              Edit
                            </button>
                          </td>
                          {fieldOptions[category]
                            ?.filter((field) =>
                              Object.values(equipment.data[category] || {}).some((item) => item[field.name] !== undefined),
                            )
                            .map(({ name }) => {
                              const value = item[name];
                              const isDateField = name === "testDate" || name === "deathDate";
                              let isNearExpiry = false;
                              let isExpired = false;

                              if (isDateField && value) {
                                const dateValue = new Date(value);
                                const today = new Date();
                                const twoMonthsFromNow = new Date();
                                twoMonthsFromNow.setMonth(today.getMonth() + 2);
                                isNearExpiry = dateValue <= twoMonthsFromNow;
                                isExpired = dateValue < today;
                              }

                              return (
                                <td key={name} className={isExpired ? "bg-danger text-light" : isNearExpiry ? "bg-warning" : ""}>
                                  {typeof value === "boolean" && value ? (
                                    <i className="bi bi-check-circle-fill text-success" />
                                  ) : (
                                    value
                                  )}
                                </td>
                              );
                            })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
        </div>
      </div>

      {/* setup profile modal */}
      <Modal centered backdrop="static" show={showSetupModal} onHide={() => setShowSetupModal(false)}>
        <Modal.Header>
          <Modal.Title>Setting Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>We&apos;re just setting up your equipment list for the first time.</Modal.Body>
      </Modal>

      {/* create equipment item modal */}
      <Modal show={showCreateEquipmentModal} onHide={() => setShowCreateEquipmentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Equipment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="name" className="mb-1 form-label">
                Category
              </Form.Label>
              <Select
                options={typeOptions}
                onChange={handleTypeChange}
                value={{ value: selectedType, label: selectedType }}
                placeholder="Equipment Type"
              />
            </Form.Group>
            {selectedType && (
              <Row className="mt-3">
                {fieldOptions[selectedType]?.map(({ name, label, type, options, note }) => (
                  <Col key={name} md={type === "textarea" ? 12 : 6}>
                    <Form.Group className="mb-2" controlId={name}>
                      <Form.Label className="mb-1">{label}</Form.Label>

                      {type === "text" && (
                        <Form.Control type="text" name={name} value={formData[name] || ""} onChange={handleInputChange} />
                      )}

                      {type === "date" && (
                        <Form.Control type="date" name={name} value={formData[name] || ""} onChange={handleInputChange} />
                      )}

                      {type === "checkbox" && (
                        <>
                          <Form.Check type="switch" name={name} checked={formData[name] || false} onChange={handleInputChange} />
                          <small className="text-muted">{note}</small>
                        </>
                      )}

                      {type === "textarea" && (
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name={name}
                          value={formData[name] || ""}
                          onChange={handleInputChange}
                        />
                      )}

                      {type === "select" && (
                        <Form.Select name={name} value={formData[name] || ""} onChange={handleInputChange}>
                          <option value="">Select...</option>
                          {options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    </Form.Group>
                  </Col>
                ))}
              </Row>
            )}
            <Button type="submit" className="btn btn-primary mt-2" onClick={handleSubmitNewEquipment}>
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* edit equipment item modal */}
      <Modal show={showEditEquipmentModal} onHide={() => setShowEditEquipmentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Equipment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="name" className="mb-1 form-label">
                Category
              </Form.Label>
              <Select
                options={typeOptions}
                onChange={handleTypeChange}
                value={{ value: selectedType, label: selectedType }}
                placeholder="Equipment Type"
              />
            </Form.Group>
            {selectedType && (
              <Row className="mt-3">
                {fieldOptions[selectedType]?.map(({ name, label, type, options, note }) => (
                  <Col key={name} md={type === "textarea" ? 12 : 6}>
                    <Form.Group className="mb-2" controlId={name}>
                      <Form.Label className="mb-1">{label}</Form.Label>

                      {type === "text" && (
                        <Form.Control type="text" name={name} value={formData[name] || ""} onChange={handleInputChange} />
                      )}

                      {type === "date" && (
                        <Form.Control type="date" name={name} value={formData[name] || ""} onChange={handleInputChange} />
                      )}

                      {type === "checkbox" && (
                        <>
                          <Form.Check type="switch" name={name} checked={formData[name] || false} onChange={handleInputChange} />
                          <small className="text-muted">{note}</small>
                        </>
                      )}

                      {type === "textarea" && (
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name={name}
                          value={formData[name] || ""}
                          onChange={handleInputChange}
                        />
                      )}

                      {type === "select" && (
                        <Form.Select name={name} value={formData[name] || ""} onChange={handleInputChange}>
                          <option value="">Select...</option>
                          {options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    </Form.Group>
                  </Col>
                ))}
              </Row>
            )}
            <Button type="submit" className="btn btn-primary mt-2" onClick={handleSubmitEditEquipment}>
              Submit
            </Button>
            <Button type="submit" className="btn btn-danger mt-2 ms-2" onClick={handleDeleteItem}>
              Delete
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
